.sticky-right {
  &__title {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
  }
  padding-bottom: 60px;
}

.sticky-radio {
  display: flex;
  flex-direction: column;
  .dflt-radio {
    margin-bottom: 12px;
    input {
      top: 51%;
    }
  }
}

.available-resumes {
  margin-top: 12px;
  &__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    color: #000000;
    margin-bottom: 12px;
    span {
      margin-left: 4px;
      margin-right: 5px;
    }
  }
}

.available-resumes {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.available-amount {
  display: flex;
  &__input {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: #333;
    padding: 10px 16px;
    max-width: 264px;
    width: 100%;
    height: 42px;
    margin-right: 24px;
    &::placeholder {
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #bdbdbd;
    }
  }
  .dflt-bttn {
    max-width: 168px;
    width: 100%;
    height: 42px;
  }
}

.grey-block {
  background: #f3f3f7;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  &__link {
    text-decoration: underline;
    color: #3362a3;
  }
  &__text {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #333333;
    margin-bottom: 6px;
  }
  &__desc {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #828282;
  }
  &__img {
    margin-right: 20px;
    max-width: 58px;
    width: 100%;
    img {
      width: 100%;
    }
  }
}

.sticky-right {
  .custom-control {
    height: 100%;
    display: flex;
    height: 27px;
    align-items: center;
    padding-left: 40px;
    cursor: pointer;
    &-label {
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #000000;
      height: 27px;
      display: flex;
      align-items: center;
      &:before {
        width: 27px;
        height: 27px;
        left: -40px;
        top: 0px;
        cursor: pointer;
        border-color: #bdbdbd;
        border-radius: 3px;
        border-width: 2px;
      }
      &:after {
        top: 2px;
        left: -40px;
        cursor: pointer;
        width: 27px;
        height: 23px;
      }
    }
    &-input {
      width: 27px;
      height: 27px;
      cursor: pointer;
      &:active {
        background-color: #fff;
      }
      &:checked {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
    background-image: url(../../../assets/image/arrow-right.svg);
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: #fff;
    border-color: #e0e0e0;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #fedc2d;
    background-color: #fedc2d;
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
    border-color: #e0e0e0;
  }
}

.connection-row {
  border-bottom: 1px solid #cdcede;
  padding-bottom: 20px;
  padding-top: 20px;
  &.first {
    padding-top: 0px;
  }
  &.last {
    border-bottom: 0px;
    padding-bottom: 36px;
  }
}

.connection-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &__right {
    display: flex;
    width: 35%;
    justify-content: flex-end;
    &-amount {
      background: #f3f3f7;
      margin-right: 12px;
      color: #000000;
      span {
        display: flex;
        align-items: center;
      }
    }
    &-yellow {
      background: #fedc2d;
      color: #000000;
    }
    &-free {
      background: #27ae60;
      color: #ffffff;
    }
  }
  &__btn {
    border-radius: 20px;
    height: 30px;
    max-width: 126px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}

.connection {
  .grey-block {
    max-width: 456px;
    width: 100%;
    &__img {
      margin-right: 24px;
    }
    &__desc {
      max-width: 278px;
      width: 100%;
    }
  }
}

.setting {
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #828282;
  &-desc {
    margin-bottom: 24px;
  }
  &-row {
    display: flex;
  }
  &-col {
    &:first-child {
      margin-right: 24px;
      max-width: 456px;
      width: 100%;
    }
    &:nth-child(2) {
      max-width: 312px;
      width: 100%;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

.drag-drop {
  min-height: 184px;
  cursor: pointer;
  background: #f3f3f7;
  border-radius: 11px;
  padding: 24px 30px;
  position: relative;
  z-index: 1;
  .drag-drop__elem-all {
    position: absolute;
    content: "";
    display: flex;
    align-items: center;
    width: calc(100% - 60px);
    margin-bottom: 16px;
    .drag-drop__elem {
      margin-bottom: 0px;
    }
    .drag-drop__elem-2 {
      max-width: 312px;
      width: 100%;
    }
  }
  &__elem-1 {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #000000;
    margin-right: 20px;
  }
  &__elem {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #cdcede;
    border-radius: 6px;
    padding: 13px 18px;
    margin-bottom: 16px;
    height: 52px;
    cursor: pointer;
  }
  &__text {
    position: absolute;
    content: "";
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #cdcede;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    p {
      max-width: 344px;
      width: 100%;
    }
  }
}

.test-work {
  display: flex;
  .dflt-select {
    font-weight: normal;
    font-size: 15px;
    color: #000000;
    &__bttn {
      height: 49px;
      display: flex;
      align-items: center;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      padding: 12px 16px;
    }
    &__dropdown {
      top: 48px;
      li {
        a {
          height: 49px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  &__col-1 {
    max-width: 360px;
    width: 100%;
    margin-right: 24px;
  }
  &__col-2 {
    max-width: 456px;
    width: 100%;
    .test-work__block {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
  }
  &__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 10px;
  }
  &__input {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 49px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    color: #333;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    &::placeholder {
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #bdbdbd;
    }
  }
  &__textarea {
    resize: none;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 130px;
    padding: 12px 16px;
    color: #333;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    &::placeholder {
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #bdbdbd;
    }
  }
}

.test-btn {
  max-width: 168px;
  font-size: 15px;
  line-height: 18px;
  height: 38px;
}

.uppload-block {
  position: relative;
  background: #f3f3f7;
  border-radius: 11px;
  z-index: 1;
  height: 233px;
  &__text {
    position: absolute;
    content: "";
    margin: 8px;
    border: 2px dashed #cdcede;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100% - 16px);
    top: 0px;
    left: 0px;
    width: calc(100% - 16px);
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #cdcede;
    z-index: 2;
  }
  &__links {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 3;
    height: calc(100% - 16px);
    overflow-y: scroll;
  }
  &__link {
    display: flex;
    padding: 13px;
    align-items: center;
    margin-bottom: 14px;
    background: #f3f3f7;
    border-radius: 11px;
    img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }
  }
}

.mobile-btn {
  display: none;
}

@media (max-width: 991px) {
  .sticky-right {
    .custom-control {
      margin-top: 0px;
      margin-bottom: 16px;
    }
    .custom-control-label {
      max-width: 100%;
      height: 27px;
      display: flex;
      align-items: center;
      &:before {
        top: -1px;
      }
      &:after {
        top: 0px;
      }
    }
  }
  .setting-row {
    flex-direction: column;
  }
  .setting-col:first-child {
    margin-right: 0px;
    max-width: 100%;
  }
  .drag-drop .drag-drop__elem-all .drag-drop__elem-2 {
    max-width: 82%;
  }
  .drag-drop__elem-1 {
    width: 18%;
  }
  .setting-col:nth-child(2) {
    padding-bottom: 0px;
  }
}

@media (max-width: 768px) {
  .mobile-btn {
    display: flex;
    margin-top: 24px;
  }
  .testing {
    .test-work__block {
      margin-bottom: 0px;
    }
  }
  .sticky-right {
    padding-bottom: 42px;
    border-bottom: 1px solid #cdcede;
    padding-top: 32px;
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }
  .setting-col {
    .drag-drop__elem {
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .test-work {
    flex-direction: column;
  }
  .test-work__col-1 {
    max-width: 100%;
    margin-right: 0px;
  }
  .test-work__col-2 {
    max-width: 100%;
    margin-top: 24px;
  }
}

@media (max-width: 576px) {
  .sticky-link {
    font-size: 15px;
    margin-bottom: 12px;
  }
  .sticky-right__title {
    font-size: 20px;
    line-height: 29px;
    margin-bottom: 22px;
  }
  .available-amount__input {
    max-width: 100%;
    margin-right: 0px;
  }
  .available-amount {
    flex-wrap: wrap;
    .dflt-bttn {
      max-width: 100%;
      margin-top: 16px;
    }
  }
  .available-resumes {
    margin-bottom: 16px;
  }
  .grey-block {
    padding: 16px 16px 34px 16px;
    flex-direction: column;
    &__img {
      margin-right: 0px;
      margin-bottom: 12px;
    }
    &__desc {
      font-size: 14px;
    }
  }
  .connection-row {
    flex-direction: column;
  }
  .connection-row__right {
    width: 100%;
    justify-content: space-between;
  }
  .connection-row__btn {
    max-width: 100%;
    font-size: 14px;
    line-height: 18px;
  }
  .connection-row__right-free {
    max-width: calc(50% - 12px);
    margin-left: auto;
  }
  .connection .grey-block {
    margin-left: auto;
    margin-right: auto;
  }
  .drag-drop {
    min-height: 126px;
    padding: 16px;
  }
  .drag-drop__elem {
    height: 41px;
    max-width: 196px;
    width: 100%;
    font-size: 13px;
    line-height: 21px;
    padding: 10px 6px;
  }
  .drag-drop__elem-1 {
    width: 74px;
  }
  .uppload-block__link {
    background: #f3f3f7;
  }
  .drag-drop .drag-drop__elem-all {
    width: calc(100% - 32px);
  }
  .drag-drop__elem-1 {
    margin-right: 4px;
    font-size: 12px;
    line-height: 21px;
  }
  .uppload-block {
    margin-top: 24px;
  }
}
