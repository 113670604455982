.schedule-time-wrapper {
  display: flex;
  padding: 20px 40px 35px 25px;
  border-radius: 11px;
  background: #ffffff;
  &__remove {
    margin-top: auto;
    text-decoration: underline;
    span {
      font-size: 12px;
      line-height: 18px;
      color: #828282;
      max-width: 106px;
      width: 100%;
      display: flex;
    }
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    width: 20%;
    padding: 10px 0;
    justify-content: space-between;
    &-bottom {
      .interview-list__name {
        border: 1px solid #cdcede;
        border-radius: 3px;
        max-width: 131px;
        width: 100%;
        padding: 6px 8px;
        font-size: 14px;
        line-height: 20px;
        color: #4f4f4f;
        min-height: 33px;
        display: flex;
        align-items: center;
        margin-top: 10px;
      }
    }
  }
  &__time {
    display: block;
    width: 100%;
    height: 22px;
    margin-bottom: 10px;
    color: #4f4f4f;
  }
  &__status {
    display: inline-block;
    margin-bottom: auto;
    padding: 2px 7px;
    border: 1px solid #ff6363;
    border-radius: 3px;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    color: #ff6363;
    &_done {
      border-color: #27ae60;
      color: #27ae60;
    }
  }
  &__list {
    width: 80%;

    &-add {
      margin-top: 15px;
      padding-left: 55px;

      .dflt-bttn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

.schedule-date-wrapper {
  &__list {
    .schedule-time-wrapper {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}

.schedule-list-item {
  &_empty {
    .schedule-list-item {
      &__info {
        span {
          &:nth-child(1) {
            width: 62%;
          }

          &:nth-child(2) {
            width: 38%;
          }
        }
      }
    }
  }
  &__status-1 {
    display: inline-block;
    width: 100%;
    padding: 3px 5px;
    border: 1px solid #ff6363;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4f4f4f;
    transition: all 0.3s ease;
    &_done {
      cursor: pointer;
    }

    &_done,
    &:hover {
      background: #ff6363;
      color: #ffffff;
    }
  }

  &__status-2 {
    display: inline-block;
    width: 100%;
    padding: 3px 5px;
    border: 1px solid #27ae60;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4f4f4f;
    transition: all 0.3s ease;
    &_done {
      cursor: pointer;
    }

    &_done,
    &:hover {
      background: #27ae60;
      color: #ffffff;
    }
  }
  &__number {
    flex-shrink: 0;
    width: 55px;
    color: #4f4f4f;
  }
  display: flex;
  align-items: center;
  padding: 8px 0;

  &__info {
    display: flex;
    align-items: center;
    width: calc(100% - 55px);

    > span {
      display: block;

      &:nth-child(1) {
        width: 40%;
        color: #4f4f4f;
      }

      &:nth-child(2) {
        width: 22%;
        text-align: center;
      }

      &:nth-child(3) {
        width: 19%;
        padding: 0 5px;
      }

      &:nth-child(4) {
        width: 19%;
        padding: 0 5px;
      }
    }
  }

  &__link {
    text-decoration: underline;
    color: #4f4f4f;
  }

  &__bttn {
    display: inline-block;
    width: 100%;
    padding: 7px 10px;
    border: 1px solid #cdcede;
    border-radius: 3px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #828282;
    transition: all 0.3s ease;
    &:hover {
      background-color: #828282;
      border-color: #cdcede;
      color: #fff;
    }
  }
}

.schedule-report {
  .schedule-list-item__info > span:nth-child(2) {
    width: 35%;
    text-align: left;
    margin-left: 40px;
  }
  .dflt-bttn {
    max-width: 184px;
    width: 100%;
    height: 30px;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .schedule-list-item__bttn {
    height: 28px;
    max-width: 57px;
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: #fff;
    }
  }
}

@media (max-width: 1200px) {
  .schedule-list-item_empty .schedule-list-item__info span {
    &:nth-child(1) {
      width: 50%;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
  .schedule-report .schedule-list-item__info > span:nth-child(2) {
    margin-left: 25px;
  }
}

@media (max-width: 991px) {
  .schedule-report .schedule-list-item__info > span:nth-child(2) {
    margin-left: 14px;
  }
  .schedule-list-item {
    &__status-1 {
      font-size: 11px;
    }
    &__status-2 {
      font-size: 11px;
    }
  }
  .schedule-list-item_empty .schedule-list-item__info {
    span:nth-child(1) {
      width: 44%;
    }
    span:nth-child(2) {
      width: 56%;
    }
  }
  .schedule-time-wrapper {
    padding: 22px 18px 32px 18px;
    flex-direction: column;
    &__info {
      padding: 0px;
      width: 100%;
      &-top {
        display: flex;
        margin-bottom: 10px;
      }
      &-bottom {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        .schedule-time-wrapper__remove {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          svg {
            margin-right: 6px;
          }
          span {
            max-width: 200px;
          }
        }
        .interview-list__title {
          margin-right: 10px;
        }
        .interview-list__name {
          margin-top: 0px;
        }
      }
    }
    &__time {
      margin-right: 19px;
    }
    .schedule-time-wrapper__list {
      width: 100%;
    }
    &__list-add {
      padding-left: 0px;
    }
  }
}

@media (max-width: 576px) {
  .schedule-time-wrapper__list-add {
    margin-top: 0px;
  }
  .schedule-list-item_empty {
    .schedule-list-item__info span {
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
  .schedule-list-item {
    &__number {
      height: 100%;
    }
  }
  .schedule-list-item__info {
    flex-wrap: wrap;
    & > span {
      &:nth-child(1) {
        width: 60%;
        margin-bottom: 11px;
      }
      &:nth-child(2) {
        width: 40%;
        text-align: right;
        margin-bottom: 11px;
      }
      &:nth-child(3) {
        width: 50%;
      }
      &:nth-child(4) {
        width: 50%;
      }
    }
  }
  .schedule-list-item__status-1 {
    height: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .schedule-list-item__status-2 {
    height: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .schedule-report .schedule-list-item__info > span:nth-child(2) {
    text-align: right;
  }
  .schedule-date-wrapper__list {
    .schedule-list-item {
      align-items: normal;
    }
  }
}

@media (max-width: 520px) {
  .schedule-list-item {
    &__info {
      width: calc(100% - 40px);
      align-items: normal;
    }
    &__number {
      width: 40px;
    }
    &__bttn {
      font-size: 12px;
      height: 30px;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .schedule-report {
    .dflt-bttn {
      font-size: 11px;
    }
    .schedule-list-item__bttn {
      font-size: 11px;
      height: 30px;
    }
  }
  .schedule-time-wrapper__info-bottom .interview-list {
    &__title {
      margin-right: 12px;
      font-size: 12px;
    }
    .dflt-select {
      min-width: 180px;
    }
    &__name {
      font-size: 12px;
    }
  }
  .schedule-time-wrapper {
    &__list {
      font-size: 12px;
      &-add .dflt-bttn {
        margin-top: 5px;
        font-size: 13px;
      }
    }
    &__info-bottom {
      .interview-list__name {
        padding: 4px 8px;
        justify-content: center;
      }
      .schedule-time-wrapper__remove {
        span {
          justify-content: center;
        }
        svg {
          margin-right: 0px;
        }
      }
    }
    &__list.schedule-report {
      .schedule-list-item__info > span {
        &:nth-child(1) {
          width: 100%;
        }
        &:nth-child(2) {
          text-align: left;
          margin-left: 0px;
          width: 50%;
        }
      }
    }
  }
  .schedule-list-item {
    &_empty .schedule-list-item__info span:nth-child(2) {
      margin-bottom: 0px;
    }
  }
}
.resume-total-count {
  margin-bottom: 16px;
  span {
    margin-right: 4px;
  }
}

.resume-table {
  border-radius: 12px 12px 12px 12px;

  &__heading {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 270px 0 40px;
    border-radius: 12px 12px 0px 0px;
    background: #f2f2f2;

    span {
      display: block;
      padding: 8px 0;
      color: #828282;

      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 12%;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 10px 40px 25px;
    border-radius: 0px 0px 12px 12px;
    list-style-type: none;
    border: 1px solid #cdcede;
    border-top: 0px;
  }
}

.resume-table-list-item {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    z-index: 11;

    .resume-table-list-item__info {
      &:before {
        opacity: 1;
      }

      span,
      a {
        color: #000000;
        font-weight: bold;
        &.dflt-select__bttn {
          font-weight: 500;
        }
      }
    }

    .resume-table-list-item__action {
      .dflt-select {
        &__bttn {
          border-color: #000000;
          color: #000000;
          font-weight: bold;
        }
      }
    }
  }

  &__info {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(100% - 230px);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: calc(100% + 13px);
      transform: translateY(-50%);
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      opacity: 0;
      background: #fedc2d;
      transition: all 0.3s ease;
    }

    span {
      display: block;
      padding: 12px 0;
      color: #828282;
      transition: all 0.3s ease;

      &:nth-child(1) {
        width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:nth-child(2) {
        width: 15%;
      }

      &:nth-child(3) {
        width: 18%;
      }

      &:nth-child(4) {
        width: 15%;
      }

      &:nth-child(5) {
        width: 12%;
      }
    }

    a {
      text-decoration: underline;
      color: #828282;
      transition: all 0.3s ease;
    }
  }

  &__action {
    flex-shrink: 0;
    width: 230px;

    .dflt-select {
      max-width: 150px;
      width: 100%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 1200px) {
  .resume-table {
    &__heading {
      padding-right: 200px;
    }
  }

  .resume-table-list-item {
    &__info {
      width: calc(100% - 160px);
    }

    &__action {
      width: 160px;
    }
  }
  .resume-table-list-item__action {
    width: 160px !important;
  }
  .resume-table-list-item__info {
    width: calc(100% - 160px);
  }
}

@media (max-width: 991px) {
  .resume-table-list-item__info span {
    &:nth-child(1) {
      width: 25%;
      white-space: normal;
    }
    &:nth-child(2) {
      width: 18%;
    }
    &:nth-child(3) {
      width: 22%;
      padding-right: 5px;
    }
    &:nth-child(4) {
      width: 20%;
      padding-right: 3px;
    }
  }
  .resume-table-list-item {
    &__action {
      display: none;
      width: 100% !important;
      margin-top: 10px;
    }
    &__info {
      width: 100%;
    }
    &:hover {
      font-weight: normal;
      color: #000;
      .resume-table-list-item__info {
        &:before {
          opacity: 0;
        }
        a,
        span {
          font-weight: normal;
          color: #000;
        }
      }
    }
  }
  .resume-table-list-item.active:hover {
    font-weight: normal;
    color: #fff;
    .resume-table-list-item__info {
      &:before {
        opacity: 0;
      }
      a,
      span {
        font-weight: normal;
        color: #fff;
      }
    }
    .resume-table-list-item__action {
      .dflt-select__bttn {
        font-weight: normal;
        border-color: #cdcede;
      }
      .dflt-select {
        margin-right: 0px;
      }
    }
  }
  .resume-table__list {
    box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
    border: none;
  }
  .resume-total-count {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 18px;
  }
  .resume-table {
    margin-left: -15px;
    margin-right: -15px;

    &__heading {
      padding: 0 15px;

      span {
        font-size: 11px;
        line-height: 20px;

        &:nth-child(1) {
          width: 25%;
        }

        &:nth-child(2) {
          width: 18%;
        }

        &:nth-child(3) {
          width: 22%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }
    }

    &__list {
      padding: 0 0 20px;
    }
  }
  .resume-table-list-item {
    flex-wrap: wrap;
    padding: 10px 15px;

    &:hover {
      .resume-table-list-item__info {
        span,
        a {
          color: #828282;
        }
      }

      .resume-table-list-item__action {
        .dflt-select {
          &__bttn {
            border-color: #cdcede;
            color: #333333;
          }
        }
      }
    }

    &__info {
      align-items: flex-start;
      width: 100%;

      &:before {
        display: none;
      }

      span {
        padding: 0;
        font-size: 12px;
        line-height: 18px;
        transition: none;

        &:nth-child(1) {
          width: 25%;
          white-space: normal;
        }

        &:nth-child(2) {
          width: 18%;
        }

        &:nth-child(3) {
          width: 22%;
          padding-right: 5px;
        }

        &:nth-child(4) {
          width: 20%;
          padding-right: 3px;
        }

        &:nth-child(5) {
          width: 15%;
        }
      }

      a {
        transition: none;
      }
    }

    &__action {
      display: none;
      width: 100%;
      margin-top: 10px;

      .dflt-select {
        margin-right: 0;
      }
    }

    &.active {
      background: #4f4f4f;

      .resume-table-list-item {
        &__info {
          span,
          a {
            color: #ffffff;
          }
        }

        &__action {
          display: block;
        }
      }
    }
  }
  .resume-page-nav {
    margin-top: 30px;

    &__pagination {
      justify-content: center;
      margin-top: 25px;

      &-prev {
        margin-left: 45px;
      }
    }
  }
}
.copple-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  .dflt-bttn_action {
    padding: 6px 8px;
    font-size: 12px;
    line-height: 18px;
    margin-right: 20px;
    svg {
      margin-right: 4px;
    }
  }
}

.dflt-bttn_action {
  &-list {
    position: relative;
  }
  &-block {
    position: absolute;

    z-index: 12;
    content: "";
    top: 50px;
    right: 0px;
    min-height: 234px;
    width: 264px;
    padding: 19px 24px 31px 24px;
    background-color: #fff;
    box-shadow: 0px 1px 10px rgba(2, 1, 72, 0.16);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    &__title {
      font-weight: 500;
      font-size: 17px;
      line-height: 29px;
      text-align: left;
      color: #4f4f4f;
      margin-bottom: 4px;
    }
    &__desc {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #4f4f4f;
    }
  }
}
.dflt-bttn_action-block {
  display: none;
  &.active {
    display: block;
  }
  &__link {
    margin-top: 7px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: #2f80ed;
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    &:hover {
      color: #2f80ed;
    }
  }
  .dflt-bttn {
    height: 41px;
    width: 100%;
    &__download {
      border: 1px solid #cdcede;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 10px;
      margin-bottom: 16px;
      font-size: 13px;
      line-height: 20px;
      color: #333333;
      img {
        width: 39px;
        height: 39px;
        margin-right: 13px;
      }
    }
    &__button {
      display: flex;
      align-items: center;
      padding: 10px;
      font-size: 13px;
      line-height: 20px;
      color: #333333;
      img {
        margin-right: 13px;
      }
    }
  }
}

@media (max-width: 991px) {
  .copple-btns {
    justify-content: flex-end;
  }
}
.addResume {
  &-newPerson {
    .dflt-input {
      height: 49px;
    }
  }
  .addResume-first {
    &.collapse-item .addResume-newPerson .addResume-title {
      display: block;
    }
    .addResume-newPerson {
      .col-lg-12 {
        margin-bottom: 18px;
      }
    }
    .dflt-bttn {
      margin-top: 18px;
    }
    .dflt-input {
      height: 49px;
      font-size: 15px;
    }
    .addEvent-block__date {
      height: 49px;
    }
  }
  .close {
    position: absolute;
    top: 16px;
    right: 17px;
    z-index: 2;
  }
  .modal-body {
    z-index: 1;
    padding: 50px 44px 78px 44px;
  }
  .addEvent-title {
    margin-bottom: 22px;
  }
  .collapse-item {
    transition: height 0s ease 0s !important;
  }
  .btn-primary {
    background-color: #fff;
    border: 1px solid #cdcede;
    border-radius: 19px;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: #333333;
    max-width: 217px;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    padding: 0px;
    .mobile {
      display: none;
    }
    &[aria-expanded="true"] {
      border-color: #fedc2d;
      border-width: 3px;
      border-style: solid;
      pointer-events: none;
    }
    &:hover {
      border-color: #fedc2d;
      outline-color: #fedc2d;
      border-width: 3px;
    }
    &:active {
      border-color: #fedc2d;
      outline-color: #fedc2d;
      border-width: 3px;
    }
    &:focus {
      border-color: #fedc2d;
      border-width: 3px;
      outline-color: #fedc2d;
      box-shadow: none;
    }
  }
  &-btns {
    margin-bottom: 28px;
    .col-lg-6 {
      &:first-child {
        .btn-primary {
          margin-left: auto;
        }
      }
      &:last-child {
        .btn-primary {
          margin-right: auto;
        }
      }
    }
  }
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: #fedc2d;
    border-color: #fedc2d;
    border-width: 3px;
    box-shadow: none;
  }
  &-title {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 13px;
  }
  .modal-dialog {
    margin-top: 97px;
    &.big {
      max-width: 1130px;
      width: 100%;
    }
    &.small {
      max-width: 642px;
      width: 100%;
    }
  }
  &-newRow {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f3f7;
    border-radius: 5px;
    svg {
      margin-right: 9px;
    }
  }
  .dflt-bttn {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    height: 50px;
    margin-top: 36px;
    max-width: 362px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &-newPerson {
    &__title {
      display: none;
    }
  }
  .collapse-item {
    .addResume-newPerson {
      .addResume-title {
        display: none;
      }
      &:nth-child(2) {
        .addResume-title {
          display: block;
        }
      }
    }
  }
  .addEvent-block__input {
    height: 49px;
  }
  .addEvent-block__date:before {
    bottom: 14px;
  }
}

@media (max-width: 991px) {
  .addResume {
    &-newPerson {
      .col-md-12 {
        margin-top: 16px;
        margin-bottom: 30px;
      }
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 24px;
        padding-bottom: 18px;
        border-top: 1px solid #cdcede;
        .addResume-newPerson__name {
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          text-align: center;
          color: #000000;
        }
      }
    }
    .collapse-item {
      .addResume-newPerson {
        .addResume-title {
          display: block;
        }
      }
    }
    &-first {
      .addResume-newPerson {
        .col-lg-4 {
          margin-top: 18px;
          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .addResume-btns {
    .btn-primary {
      font-size: 14px;
      line-height: 21px;
    }
    .col-6 {
      &:first-child {
        padding-right: 5px;
      }
      &:last-child {
        padding-left: 5px;
      }
    }
  }
  .addResume {
    .addResume-first .addResume-newPerson .col-lg-12 {
      margin-bottom: 0px;
    }
    .modal-body {
      padding: 30px 16px 46px 16px;
    }
    &-title {
      margin-top: 16px;
    }
    &-newPerson {
      .col-md-12 {
        margin-top: 0px;
      }
      .addEvent-block {
        margin-bottom: 0px;
      }
    }
    .dflt-bttn {
      margin-top: 14px;
    }
    &-first {
      .addResume-title[for="name"] {
        margin-top: 0px;
      }
      .addResume-newPerson {
        .col-lg-4 {
          margin-top: 0px;
          &:first-child {
            margin-top: 0px;
          }
        }
        .col-lg-12 {
          margin-bottom: 0px;
        }
      }
    }
    &-newPerson__title {
      padding-bottom: 0px;
    }
    .btn-primary {
      span.desktop {
        display: none;
      }
      span.mobile {
        display: block;
      }
    }
  }
}

.tempYellow {
  background: yellow;
}
