.accordion {
  &-col {
    &:first-child {
      width: 58%;
    }
    &:nth-child(2) {
      width: 42%;
    }
    &__small {
      width: 25%;
      background: #ffffff;
      border-radius: 11px;
      padding: 36px 38px 36px 38px;
      flex-direction: column;
      min-height: 674px;
      .accordion-person__pict {
        margin-right: auto;
        margin-left: auto;
        width: 61px;
        height: 61px;
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 20px;
      }
      .accordion-person__name {
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        margin-bottom: 24px;
      }
      &-name {
        display: flex;
        margin-bottom: 13px;
        font-size: 13px;
        line-height: 21px;
        color: #4f4f4f;
        font-weight: normal;
        span {
          display: flex;
          margin-left: 6px;
          font-size: 15px;
        }
        .link {
          text-decoration: underline;
          font-size: 15px;
        }
      }
      .dflt-bttn {
        width: 100%;
        height: 38px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
      }
    }
    &__big {
      width: 75%;
      background: #f3f3f7;
      padding: 40px 44px 82px 44px;
      border-radius: 0px 11px 11px 0px;
      min-height: 674px;
      position: relative;
      .actions__title {
        line-height: 20px;
        margin-bottom: 0px;
      }
    }
  }
}

.test-slider {
  padding-top: 42px;
  position: absolute;
  content: "";
  top: 40px;
  left: 44px;
  max-width: 86%;
  z-index: 2;
  .test-item__top-deny {
    background: #ff6363;
  }
  .owl-item {
    width: 168px;
    min-height: 196px;
  }
  .owl-nav {
    position: absolute;
    content: "";
    top: 0px;
    right: 0px;
    margin-top: 0px;
    .owl-prev {
      border: 1px solid #828282 !important;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      &:hover {
        background: #333333 !important;
        svg {
          fill: transparent;
        }
      }
      &.disabled {
        background-color: transparent;
        cursor: pointer;
      }
    }
    .owl-next {
      border: 1px solid #828282 !important;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      &:hover {
        background: #333333 !important;
        svg {
          fill: transparent;
        }
      }
      &.disabled {
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
  .test-item__top {
    min-height: 38px;
  }
  .test-item__bottom {
    min-height: 18px;
    padding: 20px 14px;
    &-text {
      margin-bottom: 6px;
      width: 100%;
      min-height: 36px;
      font-size: 14px;
      line-height: 20px;
    }
    .dflt-bttn {
      padding: 0px;
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .owl-stage-outer {
    padding-left: 5px;
    margin-left: -5px;
    padding-bottom: 90px;
  }
  .owl-carousel.owl-drag .owl-item {
    width: 168px !important;
  }
  .test-item {
    width: 100%;
    &__top {
      width: 100%;
    }
    &__bottom {
      width: 100%;
    }
  }
  .dflt-select {
    text-align: left;
    max-width: 134px;
    &__bttn {
      height: 38px;
      display: flex;
      align-items: center;
    }
    &__dropdown {
      top: 40px;
    }
  }
}

.accordion {
  &-bottom {
    margin-top: 250px;
    z-index: 3;
    .actions__title {
      margin-bottom: 15px;
    }
  }
  &-title {
    display: flex;
    margin-top: 34px;
    .actions__title {
      margin-bottom: 26px;
    }
    &__link {
      cursor: pointer;
      font-size: 15px;
      line-height: 21px;
      color: #4f4f4f;
      text-decoration: underline;
      margin-left: 20px;
    }
  }
  &-row {
    display: flex;
    width: 100%;
    &__statistic {
      width: 25%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &-row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    &__grafic {
      display: flex;
      width: 75%;
      &-item {
        max-width: 104px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-text {
          font-size: 13px;
          line-height: 20px;
          color: #4f4f4f;
        }
      }
    }
  }
}

$pie-color: #dc58a2;
$white: #fff;

$red: #ff6363;
$green: #27ae60;
$bg-color: #eceef4;
$yellow: #fedc2d;
$orange: #fedc2d;
$dark-yellow: #f8da00;

$pie-size: 68px;
$donut-size: 16px;
$text-size: 20px;

@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x); // IE9 only
  -o-transform: translateX($x);
  transform: translateX($x);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin position-center() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin horizontal-gradient($start-color, $end-color) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/ */
  background-image: $start-color; /* Old browsers */
  background-image: -moz-linear-gradient(
                  left,
                  $start-color 0%,
                  $start-color 50%,
                  $end-color 50%,
                  $end-color 100%
  ); /* FF3.6-15 */
  background-image: -webkit-linear-gradient(
                  left,
                  $start-color 0%,
                  $start-color 50%,
                  $end-color 50%,
                  $end-color 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(
                  to right,
                  $start-color 0%,
                  $start-color 50%,
                  $end-color 50%,
                  $end-color 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 ); /* IE6-9 */
}

.percentage-circle {
  @include horizontal-gradient($bg-color, $pie-color);
  position: relative;
  width: $pie-size;
  height: $pie-size;
  background-color: $white;
  border-radius: 50%;
  margin: 10px;
  &:before,
  &:after {
    content: "";
    display: block;
  }
  &:before {
    margin-left: 50%;
    height: 100%;
    transform-origin: left;
    border-radius: 0 100% 100% 0 / 50%;
  }
  &:after {
    @include position-center();
    border-radius: 50%;
    background: $bg-color;
    width: $pie-size - $donut-size;
    height: $pie-size - $donut-size;
    z-index: 10;
  }
  .grafic-assesment {
    @include position-center();
    @include translateX(2px);
    display: block;
    width: 100%;
    height: $text-size;
    font-size: 12px;
    text-align: center;
    z-index: 50;
  }
}

.percentage-circle {
  &.circle-red {
    $pie-color: $red;
    @include horizontal-gradient($white, $pie-color);
  }
  &.circle-orange {
    $pie-color: $orange;
    @include horizontal-gradient($white, $pie-color);
  }
  &.circle-yellow {
    $pie-color: $dark-yellow;
    @include horizontal-gradient($white, $pie-color);
  }
  &.circle-green {
    $pie-color: $green;
    @include horizontal-gradient($white, $pie-color);
  }
}

@for $i from 1 through 5 {
  .circle-#{$i} {
    &:before {
      $percentage: $i;
      $deg: ($percentage / 10) * 360deg;
      background-color: inherit;
      @include rotate($deg);
    }
  }
}

@for $i from 6 through 10 {
  .circle-#{$i} {
    &:before {
      $percentage: $i;
      $deg: (($percentage - 5) / 10) * 360deg;
      @include rotate($deg);
    }
    &.circle-red {
      $pie-color: $red;
      &:before {
        background-color: $pie-color;
      }
    }
    &.circle-orange {
      $pie-color: $orange;
      &:before {
        background-color: $pie-color;
      }
    }
    &.circle-yellow {
      $pie-color: $dark-yellow;
      &:before {
        background-color: $pie-color;
      }
    }
    &.circle-green {
      $pie-color: $green;
      &:before {
        background-color: $pie-color;
      }
    }
  }
}

.grafic-assesment {
  color: #000000;
  font-weight: normal;
  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 15px;
    color: #000;
  }
}

.statistic-row__item {
  font-weight: normal;
  font-size: 12px;
  color: #828282;
  width: 30%;
  &.statistic-row__item-actual {
    width: 70%;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;
  }
  &.accordion-status__desc {
    color: #fff;
    max-width: 70px;
    width: 100%;
    font-size: 11px;
    line-height: 20px;
  }
}

@media (max-width: 991px) {
  .test-slider {
    top: 22px;
    left: 15px;
    max-width: 96%;
    .dflt-select {
      width: 100%;
      max-width: 100%;
    }
  }
  .accordion {
    &-col {
      &__small {
        width: 100%;
        min-height: auto;
        padding: 15px 14px 30px 14px;
        .dflt-bttn {
          margin-top: 21px;
          margin-left: 61px;
          height: 31px;
          max-width: 166px;
          width: 100%;
        }
        &-name {
          margin-left: 61px;
          margin-bottom: 4px;
          span {
            font-size: 12px;
          }
        }
        &-block {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
          .accordion-person__pict {
            width: 47px;
            height: 47px;
            margin-right: 14px;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 0px;
            margin-left: 0px;
          }
          .accordion-person__name {
            margin-bottom: 0px;
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
      &__big {
        width: 100%;
        border-radius: 0px 0px 11px 11px;
        padding: 28px 14px 50px 14px;
        min-height: auto;
      }
      &:first-child {
        width: 100%;
      }
      &:nth-child(2n) {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .accordion-row {
    flex-wrap: wrap;
    &__grafic-text {
      font-size: 10px;
      line-height: 13px;
    }
    &__grafic-rating {
      transform: scale(0.8);
      .percentage-circle {
        margin: 0px;
      }
    }
    &__statistic {
      width: 100%;
      &-row {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 8px;
        }
      }
    }
    &__grafic {
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .statistic-row__item {
    width: 20%;
    &-actual {
      width: 20%;
    }
  }
  .accordion-title {
    margin-top: 26px;
  }
}

@media (max-width: 440px) {
  .accordion-col__big {
    padding-left: 8px;
    padding-right: 8px;
  }
  .test-slider {
    left: 11px;
    .test-item__bottom {
      &-text {
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 0px;
        min-height: 32px;
        &:first-child {
          min-height: auto;
        }
      }
      .dflt-select__bttn {
        height: 32px;
      }
      .dflt-bttn {
        height: 32px;
      }
    }
  }
  .accordion-tests__desc {
    font-size: 10px;
  }
  .accordion-title {
    flex-wrap: wrap;
    flex-direction: column;
    .actions__title {
      margin-bottom: 10px;
    }
    &__link {
      margin-bottom: 22px;
      margin-left: 0px;
    }
  }
  .accordion-bottom {
    margin-top: 212px;
  }
}

.addReport {
  &-form {
    display: flex;
    flex-direction: column;
  }
  .close {
    position: absolute;
    content: "";
    top: 28px;
    right: 21px;
    z-index: 2;
  }
  // .modal-body {
  //   padding: 50px 64px 72px 64px;
  //   z-index: 1;
  // }
  // .modal-dialog {
    // margin-top: 94px;
    // max-width: 686px;
  // }
  .calendar__title {
    font-size: 20px;
    line-height: 30px;
    max-width: 469px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-bottom: 28px;
  }
  &-item {
    display: flex;
    box-shadow: 0px 1px 10px rgba(2, 1, 72, 0.16);
    border-radius: 15px;
    padding: 12px 30px;
    align-items: center;
    max-width: 312px;
    width: 100%;
    height: 69px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 21px;
    cursor: default;

    &__pict {
      background: rgba(254, 220, 45, 0.6);
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      color: #000000;
      margin-right: 16px;
    }
    &__name {
      font-size: 17px;
      line-height: 29px;
      color: #333333;
    }
  }
  &-desc {
    font-size: 14px;
    line-height: 21px;
    color: #4f4f4f;
    text-align: center;
    margin-bottom: 16px;
    &__link {
      text-decoration: underline;
      font-weight: bold;
    }
  }
  &-title {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    margin-top: 20px;
    color: #333333;
    margin-bottom: 18px;
  }
  .new-account__row-btn {
    max-width: 360px;
    width: 100%;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
  }
  &-btns {
    display: flex;
    justify-content: flex-start;
    .schedule-list-item__status-2 {
      max-width: 84px;
      margin-right: 12px;
      margin-bottom: 16px;
    }
    .schedule-list-item__status-1 {
      max-width: 84px;
      margin-bottom: 16px;
    }
  }
}

.rating {
  margin-bottom: 16px;
  &-title {
    font-size: 13px;
    line-height: 20px;
    color: #4f4f4f;
    margin-right: 6px;
    span {
      font-weight: bold;
      font-size: 17px;
      line-height: 15px;
    }
  }
  &-row {
    display: flex;
    cursor: pointer;
  }
  &-bg {
    background: #cdcede;
    border-radius: 10px;
    width: calc(100% / 10 - 3px);
    height: 6px;
    margin-right: 3px;
    position: relative;
    z-index: 1;
    &__item {
      position: absolute;
      z-index: 2;
      content: "";
      background: #fedc2d;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      border-radius: 10px;
    }
  }
}

.test-item {
  box-shadow: 0px 2px 5px rgba(2, 0, 107, 0.1);
  border-radius: 11px;
  width: 147px;
  text-align: center;
  margin-right: 16px;
  min-height: 113px;
  background-color: #fff;
  cursor: pointer;
  &__top {
    min-height: 34px;
    background: #27ae60;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px 11px 0px 0px;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #ffffff;
    padding: 8px 8px;
    width: 147px;
  }
  svg {
    width: 8px;
    height: 8px;
    margin-right: 5px;
  }
  &__bottom {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    width: 147px;
    &-text {
      margin-bottom: 4px;
      color: #000000;
      font-size: 13px;
      line-height: 20px;
      span {
        font-size: 25px;
        line-height: 29px;
        font-weight: bold;
      }
    }
  }
}

.test-row {
  padding-left: 5px;
  margin-left: -5px;
  display: flex;
  width: 100%;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 5px;
  margin-right: -5px;
}

.castom-textarea {
  border: 1px solid #cdcede;
  max-width: 100%;
  min-height: 88px;
  border-radius: 5px;
  resize: none;
  padding: 12px 18px;
  &::placeholder {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #bdbdbd;
  }
}

@media (max-width: 576px) {
  .addReport {
    // .modal-body {
    //   padding: 31px 16px 48px 16px;
    // }
    // .close {
    //   top: 16px;
    //   right: 17px;
    // }
    .calendar__title {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 24px;
    }
    &-item {
      padding: 8px 18px;
      min-height: 46px;
      max-width: 260px;
      justify-content: center;
      &__pict {
        margin-right: 10px;
        width: 31px;
        height: 31px;
        font-size: 11px;
        line-height: 15px;
      }
      &__name {
        font-size: 15px;
        line-height: 29px;
      }
    }
    &-title {
      margin-bottom: 16px;
    }
    .calendar__title {
      max-width: 244px;
    }
    .new-account__row-btn {
      margin-top: 26px;
    }
  }
  .custom-textarea {
    &::placeholder {
      font-size: 13px;
    }
  }
}

.accordion {
  .card-header {
    padding: 0px;
    border: none;
    background-color: #fff;

    .btn {
      width: 100%;
      border: 0px;
      display: flex;
      align-items: center;
      padding: 10px 22px;
      box-shadow: 0px 4px 20px rgba(56, 56, 81, 0.17);
      border-radius: 5px;
      background: #fff;
      text-decoration: none;
      border: none;
      &[aria-expanded="false"] {
        .arrow-btn svg {
          transform: rotate(180deg);
        }
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-person {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: none;
    }
    &__pict {
      font-weight: bold;
      font-size: 12.0248px;
      line-height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #000000;
      background: rgba(254, 220, 45, 0.6);
      margin-right: 26px;
      text-decoration: none;
    }
    &__name {
      font-weight: 500;
      font-size: 17px;
      line-height: 29px;
      color: #333333;
    }
  }
  .card {
    border: none;
    overflow: visible;
    margin-bottom: 20px;
    &-body {
      padding: 0px;
      border: 1px solid #cdcede;
      border-radius: 11px;
      background-color: #f3f3f7;
      margin-top: 20px;
      display: flex;
    }
  }
  &-top {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &-status {
    display: flex;
    align-items: center;
    width: calc(100% / 12 * 4);
    &__name {
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #828282;
      margin-right: 10px;
    }
    &__desc {
      padding-left: 6px;
      padding-right: 6px;
      height: 22px;
      text-transform: uppercase;
      border-radius: 3px;
      font-size: 11px;
      line-height: 20px;
    }
    &__green {
      background-color: #27ae60;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    &__red {
      background: #ff6363;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &-tests {
    display: flex;
    align-items: center;
    width: calc(100% / 12 * 7);
    &__desc {
      font-size: 11px;
      line-height: 20px;
      min-width: 86px;
      border-radius: 3px;
      padding-left: 6px;
      padding-right: 6px;
      height: 22px;
      svg {
        margin-right: 6px;
      }
    }
  }
  .arrow-btn {
    display: flex;
    align-items: center;
    width: calc(100% / 12 * 1);
  }
}

@media (max-width: 991px) {
  .accordion {
    .card-body {
      flex-wrap: wrap;
    }
    &-person__pict {
      margin-right: 10px;
      width: 28px;
      height: 28px;
      font-size: 9px;
      line-height: 13px;
    }
    &-person__name {
      font-size: 15px;
      line-height: 20px;
    }
    .card-header .btn {
      flex-wrap: wrap;
      padding: 9px 14px 26px 14px;
    }

    &-top {
      width: calc(100% - 38px);
      display: flex;
      flex-direction: column;
      margin-left: auto;
    }
    .arrow-btn {
      width: 0px;
      display: none;
    }
    &-status {
      width: 100%;
      order: 2;
      &__name {
        max-width: 120px;
        width: 100%;
        font-size: 12px;
        line-height: 13px;
        text-align: left;
      }
    }
    &-tests {
      width: 100%;
      order: 1;
      margin-bottom: 7px;
    }
  }
}
