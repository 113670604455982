.search-block {
  max-width: 360px;
  width: 100%;
  position: relative;
  .label-search {
    position: absolute;
    content: "";
    top: 8px;
    left: 18px;
    margin-bottom: 0px;
  }
}

.schedule-date-wrapper {
  display: flex;
  padding: 20px 20px 20px 40px;
  border-radius: 11px;
  background: rgba(205, 206, 222, 0.5);

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__info {
    flex-shrink: 0;
    width: 15%;
    padding: 10px 0;
  }

  &__date {
    color: #000000;
  }

  &__list {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .schedule-date-wrapper {
    flex-direction: column;
    padding: 16px;
    &__info {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 11px;
    }
  }
}

.scroll-wrapper {
  position: relative;
  overflow: hidden;
  overflow-y: auto;

  .ps__rail-y {
    width: 4px;
    border-radius: 2px;
    opacity: 1;
    background: #f2f2f2;

    .ps__thumb-y {
      right: 0;
      width: 4px;
      border-radius: 2px;
      background: #bdbdbd;
    }
  }

  &:hover {
    .ps__rail-y {
      opacity: 1;
    }
  }
}

.vacancy-info-form {
  .col-lg-6 {
    &:nth-child(1) {
      .vacancy-info-form__row {
        &:nth-child(2) {
          z-index: 2;
          .form-select__current {
            z-index: 12;
          }
          .form-select__items {
            z-index: 11;
          }
        }
        &:nth-child(3) {
          z-index: 1;
        }
      }
    }
  }
}