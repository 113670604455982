.btn {
    &-company {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #333333;
        border-radius: 3px;
        height: 36px;
        width: 100%;
        cursor: pointer;
    }
    &-white {
        max-width: 88px;
        background-color: #fff;
    }
    &-yellow {
        max-width: 152px;
        background-color: #fedc2d;
        transition: all 0.3s ease;
        cursor: pointer;
        &:hover {
            background-color: #ffe600;
        }
    }
}

.btn-transparent {
    display: flex;
    align-items: center;
    padding: 8px 17px 10px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 20px;
    height: 37px;
    transition: all 0.3s ease;
    &:hover {
        background-color: #fff;
        color: #333333;
        svg {
            fill: #cdcede;
        }
    }
    &:nth-child(2) {
        padding-right: 20px;
    }
    span {
        margin-right: 6px;
    }
    svg {
        fill: #fff;
    }
    &.active {
        background-color: #fff;
        color: #333333;
        svg {
            fill: #cdcede;
        }
    }
    @media (max-width: 991px) {
        height: 30px;
        padding: 6px 14px 6px 17px;
        font-size: 13px;
        line-height: 18px;
    }
}

.btn-arrow {
    background: #f2f2f2;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: unset;
}

.btn-primary:hover {
    background-color: transparent;
    border-color: transparent;
}

@media (max-width: 472px) {
    .btn {
        &-company {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            height: 31px;
        }
        &-yellow {
            max-width: 122px;
        }
        &-white {
            max-width: 64px;
        }
    }
}
