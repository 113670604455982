.modal-dialog {
    max-width: 686px;
  }

.modal.show .modal-dialog {
    margin-left: auto;
    margin-right: auto;
}

.modal-open .modal {
    overflow-y: auto;
}

.new-account .modal-dialog {
    margin-top: 100px;
    max-width: 744px;
    width: 100%;
    min-height: 555px;
}

.new-account .modal-body {
    padding: 6px 95px 68px 95px;
}

.new-account .close {
    padding-top: 16px;
    padding-right: 17px;
    justify-content: flex-end;
    display: flex;
}

.addReport .modal-body {
    padding: 50px 64px 72px 64px;
    z-index: 1;

    @media (max-width: 576px) {
        padding: 31px 16px 48px 16px;
    }
}

.addReport .close {
    @media (max-width: 576px)  {
        top: 16px;
        right: 17px;
    }  
}

.addReport  .modal-dialog {
    max-width: 686px;
}

.statistics-dates .modal-dialog {
    max-width: 284px;
}

.statistics-dates .modal-body {
    padding-left: 34px;
    padding-right: 34px;
}