.company-display {
    margin-top: 40px;

    &__title {
        display: flex;
        align-items: center;
        margin: 0 0 15px;
        font-size: 17px;
        line-height: 29px;
        color: #4f4f4f;

        &:after {
            content: attr(data-count);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin-left: 10px;
            border-radius: 50%;
            font-weight: 700;
            font-size: 13px;
            line-height: 18px;
            color: #ffffff;
            background: #cdcede;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            &:not(:last-child) {
                margin-bottom: 15px;
            }

            a {
                display: block;
                padding: 12px;
                border: 1px solid #cdcede;
                border-radius: 3px;
                text-align: center;
                color: #4f4f4f;
                transition: all 0.3s ease;

                &:hover {
                    color: #000000;
                }
            }

            &.active {
                a {
                    font-weight: 500;
                    border-color: rgba(205, 206, 222, 0.5);
                    background: rgba(205, 206, 222, 0.5);
                    color: #000000;
                }
            }
        }
    }

    &_2 {
        .company-display {
            &__title {
                font-weight: 400;
                color: #4f4f4f;

                &:after {
                    display: none;
                }
            }
        }
    }
}
