.addReport {
    .flatpickr-calendar.open {
        max-width: 240px;
        border: 0.99866px solid rgba(205, 206, 222, 0.7);
        filter: drop-shadow(0px 0.99866px 9.9866px rgba(2, 1, 72, 0.16));
        border-radius: 5px;
        padding: 25px 26px;
        padding-bottom: 30px;
        background-color: #ffffff;

        @media (min-width: 768px) {
        max-width: 310px;
        }
    }

    .flatpickr-calendar.open:before{
        content: "";
        width: 40px;
        height: 40px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        display: block!important;
        background-color: #fff;
        top: -5px;
        left: calc(50% - 28px);
        border-bottom-color: #fff!important;
        border-radius: 5px;
        box-shadow: 0 1px 10px rgba(2,1,72,.16)!important;
    }

    .flatpickr-calendar.open:after {
        position: absolute;
        content: "";
        width: 80px;
        height: 25px;
        top: 0;
        left: calc(50% - 40px);
        display: block!important;
        background-color: #fff;
    }

    .flatpickr-calendar.arrowTop:after {
        border-bottom-color: #fff;
    }

    .flatpickr-months .flatpickr-month {
        background-color: #ffffff;
    }

    .flatpickr-current-month {
        width: 100%;
        left: 0;
        justify-content: center;
        padding-top: 0;
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
        background-color: #ffffff;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 13px;
        line-height: 22px;
        text-align: center;
        color: #333333;
        pointer-events: none;
        padding-left: 30px;

        &:hover {
        background-color: #ffffff;
        }

        @media (min-width: 768px) {
        font-size: 17px;
        line-height: 29px;
        }
    }

    .flatpickr-current-month input.cur-year {
        font-size: 13px;
        line-height: 22px;
        color: #333333;
        background-color: #ffffff;

        &:hover {
        background-color: #ffffff;
        }
        @media (min-width: 768px) {
        font-size: 17px;
        line-height: 29px;
        }
    }

    .flatpickr-months .flatpickr-prev-month, 
    .flatpickr-months .flatpickr-next-month {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 19px;
        border: 1px solid #333333;
        background-color: #ffffff;
        border-radius: 50%;
        top: 25px;
        right: 26px!important;

        svg {
        fill: #333333;
        width: 10px;
        height: 14px;
        } 

        &:hover,
        &:focus {
        background-color: #333333;
        }

        &:hover svg,
        &:focus svg {
        fill: #ffffff;
        }
    }

    .flatpickr-months .flatpickr-prev-month {
        left: 26px!important;
    }

    .flatpickr-weekdays {
        background-color: #ffffff;
    }

    span.flatpickr-weekday {
        font-size: 11px;
        line-height: 16px;
        color: #333333;
        background-color: #ffffff;
        text-transform: uppercase;
        flex: none;
        margin-right: 14px;
        font-weight: 400;

        &:last-of-type {
        margin-right: 0px;
        }

        @media (min-width: 768px) {
        font-size: 15px;
        line-height: 21px;
        margin-right: 20px;
        }
    }

    .flatpickr-days {
        border: none;
        width: 100%;
        overflow: visible;
    }

    .flatpickr-innerContainer {
        border: none;
        width: 100%;
        overflow: visible;
    }

    .flatpickr-rContainer {
        width: 100%;
        max-width: 100%; 
    }

    .dayContainer {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 15px);
        column-gap: 14px;
        row-gap: 7px;

        @media (min-width: 768px) {
        column-gap: 20px;
        }
    }

    .flatpickr-day {
        display: block;
        width: 15px;
        font-size: 11px;
        line-height: 16px;
        flex-basis: 0;
        height: auto;
        color: #4F4F4F;
        text-align: center;

        &:hover,
        &:focus  {
        background-color: transparent;
        border-color: transparent;
        color: #4F4F4F;
        }

        @media (min-width: 768px) {
        width: 17px;
        font-size: 15px;
        line-height: 21px;
        }
    }

    .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
        background-color: transparent;
        border-color: transparent;
        color: #4F4F4F;
    }

    .flatpickr-day.selected {
        position: relative;
        background-color: transparent;
        border-color: transparent;
        color: #4F4F4F;

        &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        background-color: #FEDC2D;
        top: -3px;
        left: 50%;
        transform: translateX(-50%);

        @media (min-width: 768px) {
            width: 27px;
            height: 27px;
        }
        }
    }

    .flatpickr-day.today {
        border-color: transparent;
        font-weight: bold;
        &:hover,
        &:focus  {
        background-color: transparent;
        border-color: transparent;
        color: #4F4F4F;
        }
    }

    .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
        background-color: transparent;
        border-color: transparent;
        color: #4F4F4F;
    }

    .flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
        position: relative;
        z-index: 1;
    }

    .flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus,
    .numInputWrapper span:hover, .numInputWrapper:hover {
        background-color: #ffffff;
    }
}