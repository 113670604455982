.company-table {
  margin-bottom: 48px;
  &__top {
    background: #ededf3;
    border-radius: 11px 11px 0px 0px;
    padding-left: 21px;
    padding-right: 21px;
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #828282;
    border: 1.16053px solid #cdcede;
    border-bottom: none;
  }
  &__row {
    display: flex;
    padding-top: 8px;
    padding-bottom: 10px;
    width: 100%;
    transition: all 0.3s ease;
    &-item {
      padding-right: 17px;
      max-width: calc(100% / 6);
      width: 100%;
      &.last {
        max-width: 60px;
        width: 100%;
      }
      &:first-child {
        max-width: calc(100% / 3);
        width: 100%;
      }
      &:last-child {
        padding-right: 0px;
      }
    }
  }
  &__body {
    padding-left: 23px;
    padding-right: 23px;

    border: 1.16053px solid #cdcede;
    border-top: none;
    box-sizing: border-box;
    border-radius: 0px 0px 11px 11px;
    .company-table__row {
      padding-top: 22px;
      padding-bottom: 4px;
      font-weight: normal;
      font-size: 15px;
      line-height: 21px;
      color: #4f4f4f;
      &:hover {
        font-weight: bold;
        color: #000000;
        cursor: pointer;
        svg {
          path {
            fill: #828282;
          }
        }
      }

      &:last-child {
        padding-bottom: 49px;
      }
    }
  }
  &__icons {
    display: flex;
    justify-content: flex-end;
    &-pen {
      margin-right: 25px;
    }
  }
  &.mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .company-table {
    &.mobile {
      display: block;
      p {
        margin-bottom: 0px;
      }
      .company-table {
        &__column {
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: #828282;
          &-item {
            margin-bottom: 10px;
          }
          .status {
            font-weight: bold;
            font-size: 13px;
            line-height: 20px;
            color: #333333;
          }
        }
        &__row-item {
          &:first-child {
            max-width: calc(100% / 12 * 5);
          }
          &:nth-child(2) {
            max-width: calc(100% / 12 * 5);
          }
          &:last-child {
            max-width: calc(100% / 12 * 2);
          }
        }
        &__top {
          margin-top: 15px;
          font-size: 12px;
          line-height: 18px;
          padding-left: 10px;
          padding-right: 10px;
        }
        &__icons {
          flex-direction: column;
          align-items: flex-end;
          padding-right: 10px;
          &-pen {
            margin-right: 0px;
            margin-bottom: 26px;
          }
          &-delete {
            svg {
              width: 19px;
            }
          }
        }
        &__row {
          &-block {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
          }
        }
        &__body {
          padding-left: 11px;
          padding-right: 11px;
          .company-table__row {
            font-size: 12px;
            line-height: 18px;
            &:first-child {
              padding-top: 10px;
            }
            &:last-child {
              padding-bottom: 18px;
            }
          }
        }
      }
    }
    &.desktop {
      display: none;
    }
  }
}
.company-amount {
  padding-top: 18px;
  padding-bottom: 26px;
  &__title {
    font-size: 20px;
    line-height: 21px;
    color: #333333;
    height: 100%;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-yellow {
      max-width: 221px;
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 18px;
      }
      span {
        margin-right: 9px;
      }
    }
  }
}

@media (max-width: 991px) {
  .company-amount {
    padding-top: 24px;
    padding-bottom: 0px;
    .col-xl-5 {
      order: 2;
    }
    .col-xl-7 {
      order: 1;
    }
    &__block {
      margin-bottom: 24px;
      .btn-yellow {
        margin-right: 22px;
      }
    }
    &__title {
      font-size: 15px;
      line-height: 21px;
      color: #333333;
    }
  }
}

@media (max-width: 576px) {
  .company-amount__block {
    flex-direction: column;
    .btn-yellow {
      margin-right: 0px;
      margin-bottom: 22px;
      height: 41px;
    }
  }
}

.company-header {
  background: #67b7f0;
  padding-top: 14px;
  padding-bottom: 14px;
  // &__name {
  //   font-weight: 500;
  //   font-size: 25px;
  //   line-height: 29px;
  //   color: #ffffff;
  //   margin-bottom: 0px;
  //   height: 100%;
  //   display: flex;
  //   align-items: center;
  // }
}

// .company-header {
//   &__btns {
//     display: flex;
//     justify-content: space-between;
//   }
// }

@media (max-width: 991px) {
  .company-header {
    padding-bottom: 28px;
    // &__name {
    //   margin-bottom: 8px;
    //   font-size: 20px;
    //   line-height: 29px;
    // }
  }
}

@media (max-width: 768px) {
  .company-header__mobile-btns {
    // overflow-x: scroll;
    // width: 100%;
    // height: 38px;
    .company-header__btns {
      // height: 100%;
      // width: 580px;
    }
  }
}

.company-bottom {
  &__btn {
    position: relative;
    @media (max-width: 991px) {
      margin-top: 30px;
    }
    .btn-yellow {
      max-width: 264px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 991px) {
        height: 41px;
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  &__footer {
    position: absolute;
    content: "";
    bottom: 0px;
    right: 0;
    display: flex;
    align-items: center;
    height: 100%;
    @media (max-width: 991px) {
      bottom: -62px;
      right: calc(50% - 102px);
    }
  }
  &__numb {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
  }
  &__all {
    margin-left: 13px;
    font-size: 12px;
    line-height: 18px;
    color: #4f4f4f;
    span {
      margin-left: 2px;
    }
  }
}