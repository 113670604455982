.dflt-bttn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    background: #fedc2d;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover,
    &:active {
        color: #000000;
        background: #ffe600;
    }

    &_action {
        display: inline-flex;
        align-items: center;
        padding: 6px 14px;
        border: 1px solid #cdcede;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #4f4f4f;
        background: transparent;

        svg {
            margin-right: 7px;

            path {
                transition: all 0.3s ease;
            }
        }

        &:hover {
            border-color: #000000;
            background: transparent;

            svg {
                path {
                    fill: #000000;
                }
            }
        }
    }
}

.dflt-input_phone {
    &::placeholder {
        color: #bdbdbd !important;
        opacity: 1 !important;
    }
}

.dflt-input {
    display: block;
    width: 100%;
    padding: 12px 18px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    color: #000000;
    height: 49px;
    &::placeholder {
        opacity: 0.3;
    }

    &_code {
        text-align: center;
    }
}

.dflt-textarea {
    min-height: 106px;
    &.link-textarea {
        min-height: 92px;
    }
}

.dflt-password {
    position: relative;

    .dflt-input {
        padding-right: 45px;
    }

    &__toggle {
        position: absolute;
        top: calc(50% - 8px);
        right: 15px;
        z-index: 5;
        transform: translateY(-50%);
        width: 25px;
        height: 25px;

        input[type="checkbox"] {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 5;
            transform: translateX(-50%) translateY(-50%);
            opacity: 0;

            &:checked + label {
                svg {
                    &:first-child {
                        display: none;
                    }

                    &:last-child {
                        display: block;
                    }
                }
            }
        }

        label {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;

            svg {
                &:last-child {
                    display: none;
                }
            }
        }
    }

    &__text {
        height: 12px;
        margin-top: 4px;
        padding-left: 2px;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
}

.dflt-textarea {
    display: block;
    width: 100%;
    padding: 12px 18px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    resize: none;
    color: #000000;

    &::placeholder {
        opacity: 0.3;
    }
}

.dflt-radio {
    position: relative;
    display: inline-block;

    input {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;

        &:checked + label {
            color: #000000;

            &:before {
                border-color: #fedc2d;
                background: #fedc2d;
            }
        }
    }

    label {
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        margin: 0;
        color: #4f4f4f;
        transition: all 0.3s ease;

        span {
            cursor: pointer;
        }

        &:hover {
            color: #000000;
        }

        &:before {
            content: "";
            display: block;
            width: 13px;
            height: 13px;
            margin-right: 12px;
            border-radius: 50%;
            border: 1px solid #828282;
            cursor: pointer;
            transition: all 0.3s ease;
        }
    }
}

.dflt-schedule {
    &__heading {
        padding: 6px 25px;
        border-radius: 12px 12px 0px 0px;
        font-size: 13px;
        line-height: 20px;
        background: #fedc2d;
        color: #333333;
    }

    &__content {
        display: flex;
        align-items: stretch;
        min-height: 140px;
        padding: 15px 0;
        border-radius: 0px 0px 12px 12px;
        background: #ffffff;
    }

    &__separator {
        width: 1px;
        background: #cdcede;
    }

    &__column {
        flex-grow: 1;
        padding: 0 10px;
    }

    &__row {
        display: flex;
        justify-content: space-around;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;

        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }

    &_ghost {
        .dflt-schedule__heading,
        .dflt-schedule__content {
            border: 1px solid #ffffff;
            background: transparent;
        }

        .dflt-schedule__heading {
            padding-left: 20px;
            padding-right: 20px;
            color: #ffffff;
        }

        .dflt-schedule__content {
            border-top: 0;
        }

        .dflt-schedule__row {
            color: #ffffff;
        }
    }
}

.dflt-search {
    position: relative;
    width: 100%;
    margin-bottom: 0px;
    input[type="text"] {
        display: block;
        width: 100%;
        padding: 8px 10px 8px 40px;
        border: 1px solid #cdcede;
        border-radius: 25px;
        color: #000000;

        &::placeholder {
            opacity: 0.3;
        }
    }

    button[type="submit"] {
        position: absolute;
        left: 5px;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        border-radius: 50%;
        box-shadow: none;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }

        &:hover,
        &:active {
            background: rgba(0, 0, 0, 0.1);
        }
    }
}

.test-btn {
    &.dflt-bttn {
        padding: 0px;
    }
}

.dflt-select {
    position: relative;
    z-index: 10;
    min-width: 125px;
    border-radius: 5px;
    background: #ffffff;

    &__bttn {
        position: relative;
        z-index: 10;
        display: block;
        padding: 5px 10px;
        border: 1px solid #cdcede;
        border-radius: 3px;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        background-color: #ffffff;
        background-image: url("./image/arrow-down-dark.svg");
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 9px auto;
        transition: all 0.3s ease;
    }

    &__dropdown {
        position: absolute;
        left: 0;
        top: 32px;
        z-index: 11;
        display: none;
        width: 100%;
        margin: 0;
        padding: 6px 0px;
        border-radius: 5px;
        list-style-type: none;
        border: 1px solid #cdcede;
        background: #ffffff;
        transition: all 0.3s ease;

        li {
            a {
                display: block;
                padding: 5px 10px;
                font-size: 14px;
                line-height: 15px;
                color: #333333;
                transition: all 0.3s ease;

                &:hover {
                    background: #4f4f4f;
                    color: #ffffff;
                }
            }
        }
    }

    &.active {
        .dflt-select__dropdown {
            display: block;
            box-shadow: 0px 1px 10px rgba(2, 1, 72, 0.15);
        }
    }
}

.dflt-search__ankets {
    margin-bottom: 0px;
}

@media (max-width: 991px) {
    .dflt-search {
        input[type="text"] {
            font-size: 13px;
            line-height: 20px;
        }
    }

    .dflt-bttn {
        padding: 12px 16px;
        font-size: 14px;
        line-height: 18px;

        &_action {
            padding: 5px 8px;
            font-size: 12px;
            line-height: 18px;

            svg {
                margin-right: 4px;
            }
        }
    }

    .dflt-select {
        &__bttn {
            padding: 4px 10px;
            font-size: 13px;
            line-height: 20px;
        }

        &__dropdown {
            li {
                a {
                    font-size: 12px;
                    line-height: 15px;

                    &:hover {
                        background: #828282;
                    }
                }
            }
        }
    }

    .dflt-radio {
        label {
            span {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    .dflt-input {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20px;
    }

    .dflt-textarea {
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20px;
    }
}
