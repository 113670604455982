.accordion {
  &-col {
    &:first-child {
      width: 58%;
    }
    &:nth-child(2) {
      width: 42%;
    }
    &__small {
      width: 25%;
      background: #ffffff;
      border-radius: 11px;
      padding: 36px 38px 36px 38px;
      flex-direction: column;
      min-height: 674px;
      .accordion-person__pict {
        margin-right: auto;
        margin-left: auto;
        width: 61px;
        height: 61px;
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 20px;
      }
      .accordion-person__name {
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        margin-bottom: 24px;
      }
      &-name {
        display: flex;
        margin-bottom: 13px;
        font-size: 13px;
        line-height: 21px;
        color: #4f4f4f;
        font-weight: normal;
        span {
          display: flex;
          margin-left: 6px;
          font-size: 15px;
        }
        .link {
          text-decoration: underline;
          font-size: 15px;
        }
      }
      .dflt-bttn {
        width: 100%;
        height: 38px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
      }
    }
    &__big {
      width: 75%;
      background: #f3f3f7;
      padding: 40px 44px 82px 44px;
      border-radius: 0px 11px 11px 0px;
      min-height: 674px;
      position: relative;
      .actions__title {
        line-height: 20px;
        margin-bottom: 0px;
      }
    }
  }
}

.test-slider {
  padding-top: 42px;
  position: absolute;
  content: "";
  top: 40px;
  left: 44px;
  max-width: 86%;
  z-index: 2;
  .test-item__top-deny {
    background: #ff6363;
  }
  .owl-item {
    width: 168px;
    min-height: 196px;
  }
  .owl-nav {
    position: absolute;
    content: "";
    top: 0px;
    right: 0px;
    margin-top: 0px;
    .owl-prev {
      border: 1px solid #828282 !important;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      &:hover {
        background: #333333 !important;
        svg {
          fill: transparent;
        }
      }
      &.disabled {
        background-color: transparent;
        cursor: pointer;
      }
    }
    .owl-next {
      border: 1px solid #828282 !important;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      &:hover {
        background: #333333 !important;
        svg {
          fill: transparent;
        }
      }
      &.disabled {
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
  .test-item__top {
    min-height: 38px;
  }
  .test-item__bottom {
    min-height: 18px;
    padding: 20px 14px;
    &-text {
      margin-bottom: 6px;
      width: 100%;
      min-height: 36px;
      font-size: 14px;
      line-height: 20px;
    }
    .dflt-bttn {
      padding: 0px;
      width: 100%;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .owl-stage-outer {
    padding-left: 5px;
    margin-left: -5px;
    padding-bottom: 90px;
  }
  .owl-carousel.owl-drag .owl-item {
    width: 168px !important;
  }
  .test-item {
    width: 100%;
    &__top {
      width: 100%;
    }
    &__bottom {
      width: 100%;
    }
  }
  .dflt-select {
    text-align: left;
    max-width: 134px;
    &__bttn {
      height: 38px;
      display: flex;
      align-items: center;
    }
    &__dropdown {
      top: 40px;
    }
  }
}

.accordion {
  &-bottom {
    margin-top: 250px;
    z-index: 3;
    .actions__title {
      margin-bottom: 15px;
    }
  }
  &-title {
    display: flex;
    margin-top: 34px;
    .actions__title {
      margin-bottom: 26px;
    }
    &__link {
      cursor: pointer;
      font-size: 15px;
      line-height: 21px;
      color: #4f4f4f;
      text-decoration: underline;
      margin-left: 20px;
    }
  }
  &-row {
    display: flex;
    width: 100%;
    &__statistic {
      width: 25%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &-row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    &__grafic {
      display: flex;
      width: 75%;
      &-item {
        max-width: 104px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        &-text {
          font-size: 13px;
          line-height: 20px;
          color: #4f4f4f;
        }
      }
    }
  }
}

$pie-color: #dc58a2;
$white: #fff;

$red: #ff6363;
$green: #27ae60;
$bg-color: #eceef4;
$yellow: #fedc2d;
$orange: #fedc2d;
$dark-yellow: #f8da00;

$pie-size: 68px;
$donut-size: 16px;
$text-size: 20px;

@mixin translateX($x) {
  -webkit-transform: translateX($x);
  -ms-transform: translateX($x); // IE9 only
  -o-transform: translateX($x);
  transform: translateX($x);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin position-center() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin horizontal-gradient($start-color, $end-color) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/ */
  background-image: $start-color; /* Old browsers */
  background-image: -moz-linear-gradient(
                  left,
                  $start-color 0%,
                  $start-color 50%,
                  $end-color 50%,
                  $end-color 100%
  ); /* FF3.6-15 */
  background-image: -webkit-linear-gradient(
                  left,
                  $start-color 0%,
                  $start-color 50%,
                  $end-color 50%,
                  $end-color 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background-image: linear-gradient(
                  to right,
                  $start-color 0%,
                  $start-color 50%,
                  $end-color 50%,
                  $end-color 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 ); /* IE6-9 */
}

.percentage-circle {
  @include horizontal-gradient($bg-color, $pie-color);
  position: relative;
  width: $pie-size;
  height: $pie-size;
  background-color: $white;
  border-radius: 50%;
  margin: 10px;
  &:before,
  &:after {
    content: "";
    display: block;
  }
  &:before {
    margin-left: 50%;
    height: 100%;
    transform-origin: left;
    border-radius: 0 100% 100% 0 / 50%;
  }
  &:after {
    @include position-center();
    border-radius: 50%;
    background: $bg-color;
    width: $pie-size - $donut-size;
    height: $pie-size - $donut-size;
    z-index: 10;
  }
  .grafic-assesment {
    @include position-center();
    @include translateX(2px);
    display: block;
    width: 100%;
    height: $text-size;
    font-size: 12px;
    text-align: center;
    z-index: 50;
  }
}

.percentage-circle {
  &.circle-red {
    $pie-color: $red;
    @include horizontal-gradient($white, $pie-color);
  }
  &.circle-orange {
    $pie-color: $orange;
    @include horizontal-gradient($white, $pie-color);
  }
  &.circle-yellow {
    $pie-color: $dark-yellow;
    @include horizontal-gradient($white, $pie-color);
  }
  &.circle-green {
    $pie-color: $green;
    @include horizontal-gradient($white, $pie-color);
  }
}

@for $i from 1 through 5 {
  .circle-#{$i} {
    &:before {
      $percentage: $i;
      $deg: ($percentage / 10) * 360deg;
      background-color: inherit;
      @include rotate($deg);
    }
  }
}

@for $i from 6 through 10 {
  .circle-#{$i} {
    &:before {
      $percentage: $i;
      $deg: (($percentage - 5) / 10) * 360deg;
      @include rotate($deg);
    }
    &.circle-red {
      $pie-color: $red;
      &:before {
        background-color: $pie-color;
      }
    }
    &.circle-orange {
      $pie-color: $orange;
      &:before {
        background-color: $pie-color;
      }
    }
    &.circle-yellow {
      $pie-color: $dark-yellow;
      &:before {
        background-color: $pie-color;
      }
    }
    &.circle-green {
      $pie-color: $green;
      &:before {
        background-color: $pie-color;
      }
    }
  }
}

.grafic-assesment {
  color: #000000;
  font-weight: normal;
  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 15px;
    color: #000;
  }
}

.statistic-row__item {
  font-weight: normal;
  font-size: 12px;
  color: #828282;
  width: 30%;
  &.statistic-row__item-actual {
    width: 70%;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #4f4f4f;
  }
  &.accordion-status__desc {
    color: #fff;
    max-width: 70px;
    width: 100%;
    font-size: 11px;
    line-height: 20px;
  }
}

@media (max-width: 991px) {
  .test-slider {
    top: 22px;
    left: 15px;
    max-width: 96%;
    .dflt-select {
      width: 100%;
      max-width: 100%;
    }
  }
  .accordion {
    &-col {
      &__small {
        width: 100%;
        min-height: auto;
        padding: 15px 14px 30px 14px;
        .dflt-bttn {
          margin-top: 21px;
          margin-left: 61px;
          height: 31px;
          max-width: 166px;
          width: 100%;
        }
        &-name {
          margin-left: 61px;
          margin-bottom: 4px;
          span {
            font-size: 12px;
          }
        }
        &-block {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
          .accordion-person__pict {
            width: 47px;
            height: 47px;
            margin-right: 14px;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 0px;
            margin-left: 0px;
          }
          .accordion-person__name {
            margin-bottom: 0px;
            font-size: 15px;
            line-height: 21px;
          }
        }
      }
      &__big {
        width: 100%;
        border-radius: 0px 0px 11px 11px;
        padding: 28px 14px 50px 14px;
        min-height: auto;
      }
      &:first-child {
        width: 100%;
      }
      &:nth-child(2n) {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .accordion-row {
    flex-wrap: wrap;
    &__grafic-text {
      font-size: 10px;
      line-height: 13px;
    }
    &__grafic-rating {
      transform: scale(0.8);
      .percentage-circle {
        margin: 0px;
      }
    }
    &__statistic {
      width: 100%;
      &-row {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 8px;
        }
      }
    }
    &__grafic {
      width: 100%;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .statistic-row__item {
    width: 20%;
    &-actual {
      width: 20%;
    }
  }
  .accordion-title {
    margin-top: 26px;
  }
}

@media (max-width: 440px) {
  .accordion-col__big {
    padding-left: 8px;
    padding-right: 8px;
  }
  .test-slider {
    left: 11px;
    .test-item__bottom {
      &-text {
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 0px;
        min-height: 32px;
        &:first-child {
          min-height: auto;
        }
      }
      .dflt-select__bttn {
        height: 32px;
      }
      .dflt-bttn {
        height: 32px;
      }
    }
  }
  .accordion-tests__desc {
    font-size: 10px;
  }
  .accordion-title {
    flex-wrap: wrap;
    flex-direction: column;
    .actions__title {
      margin-bottom: 10px;
    }
    &__link {
      margin-bottom: 22px;
      margin-left: 0px;
    }
  }
  .accordion-bottom {
    margin-top: 212px;
  }
}
.actions {
  margin-top: 25px;

  &__title {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
    font-weight: 700;
    font-size: 17px;
    line-height: 29px;
    color: #000000;

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin-left: 18px;
      border-radius: 50%;
      background: #333333;

      svg {
        width: 5px;
        margin-left: 2px;
      }
    }
  }
}

.actions-slider {
  position: relative;
  z-index: 100;

  &.owl-carousel.owl-theme {
    .owl-nav {
      position: absolute;
      right: 0;
      bottom: calc(100% + 20px);
      margin: 0;

      .owl-prev,
      .owl-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        margin: 0;
        padding: 0;
        border: 1px solid #828282;
        border-radius: 50%;
        background: transparent;
        transition: all 0.3s ease;

        svg {
          width: 5px;

          path {
            stroke: #828282;
            transition: all 0.3s ease;
          }
        }

        &:hover {
          border-color: #333333;
          background: #333333;

          svg {
            path {
              stroke: #ffffff;
            }
          }
        }
      }

      .owl-prev {
        margin-right: 15px;
      }
    }
  }
}

.actions-slider-item {
  display: flex;
  flex-wrap: wrap;
  height: 325px;
  padding: 25px 25px 30px;
  border: 1px solid rgba(205, 206, 222, 0.8);
  border-radius: 5px;
  background: #ffffff;

  &__icon {
    position: relative;
    flex-shrink: 0;
    width: 85px;
    height: 85px;
    margin: 0 auto 20px;

    &:before {
      content: attr(data-count);
      position: absolute;
      left: 63px;
      top: 5px;
      z-index: 10;
      display: block;
      min-width: 21px;
      padding: 3px 7px;
      border-radius: 10px;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      text-align: center;
      background: #ff6363;
      color: #ffffff;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 17px;
    line-height: 29px;
    text-align: center;
    color: #333333;
  }

  &__text {
    display: block;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4f4f4f;
  }

  .dflt-bttn {
    margin-top: auto;
    padding: 10px;
  }
}

@media (max-width: 991px) {
  .actions-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .actions-slider-item {
    width: calc(50% - 15px);
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .actions {
    &__title {
      margin-bottom: 15px;
      line-height: 23px;

      span {
        width: 20px;
        height: 20px;

        svg {
          width: 3.5px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .actions-slider-item {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    padding: 15px 15px 25px 15px;
    box-shadow: 0px 3.27692px 16.3846px rgba(56, 56, 81, 0.17);
    border: 0px;

    &__icon {
      width: 60px;
      height: 60px;
      margin: 0 35px 0 0;

      &:before {
        left: 45px;
        top: 3px;
        min-width: 18px;
        padding: 1px 5px;
        font-size: 9px;
        line-height: 15px;
      }
    }

    &__info {
      width: calc(100% - 95px);
    }

    &__title {
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 20px;
      text-align: left;
    }

    &__text {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 20px;
      text-align: left;
    }

    .dflt-bttn {
      max-width: 115px;
      margin: 0;
      padding: 10px;
      font-size: 13px;
      line-height: 17px;
    }
  }
}
