.form-select {
    position: relative;

    &__source {
        display: none;
    }

    &__current {
        position: relative;
        display: flex;
        align-items: center;
        z-index: 10;
        width: 100%;
        height: 49px;
        padding: 12px;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        color: #333333;
        background-color: #ffffff;
        background-image: url("./image/arrow-down-dark.svg");
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 9px auto;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    &__items {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        width: 100%;
        margin: 0;
        padding: 53px 0 6px;
        border-radius: 5px;
        list-style-type: none;
        border: 1px solid #e0e0e0;
        background: #ffffff;
        transition: all 0.3s ease;

        &_hide {
            display: none;
        }

        &-single {
            display: block;
            padding: 5px 10px;
            font-size: 14px;
            line-height: 15px;
            color: #333333;
            transition: all 0.3s ease;
            cursor: pointer;

            &:hover {
                background: #4f4f4f;
                color: #ffffff;
            }
        }

        &-selected {
            background: #4f4f4f;
            color: #ffffff;
        }
    }
}

@media (max-width: 991px) {
    .form-select {
        &__current {
            padding: 10px 12px;
            line-height: 20px;
        }
    }
}
