input[type="text"],
textarea {
    appearance: none;
}

.search-input {
    font-size: 15px;
    line-height: 21px;
    color: #333;
    font-weight: normal;
    border: 1px solid #cdcede;
    border-radius: 25px;
    max-width: 360px;
    width: 100%;
    height: 39px;
    display: flex;
    padding: 8px 16px 10px 38px;
    align-items: center;
    position: relative;
    &::placeholder {
        color: #bdbdbd;
    }
}

.new-account__input {
    &::placeholder {
        color: #bdbdbd !important;
    }
}

input[type="checkbox" i] {
    -webkit-appearance: none;
    &:focus {
        outline-offset: 0px;
        box-shadow: none;
        outline: none;
    }
}
input[type="checkbox"] {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}
